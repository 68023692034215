import { LOCALE } from './locale';

const formatter = new Intl.DateTimeFormat(LOCALE, {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

export const dateFormat = (date: Date | string) => {
  return formatter.format(new Date(date));
};
