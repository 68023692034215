import GatsbyImage from 'gatsby-image';
import { shuffle } from 'lodash';
import React, { Fragment, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from 'src/components/layout/layout';
import FeedProductListDesktop from 'src/components/list/list-feed.desktop';
import SEO from 'src/components/misc/seo';
import ProductCardGuide from 'src/components/product/card.guide';
import ProductCardGuideMobile from 'src/components/product/card.guide.mobile';
import Container from 'src/components/widget/container';
import Divider from 'src/components/widget/divider';
import WideContainer from 'src/components/widget/wide-container';
import { COLOR_GRAY } from 'src/constants';
import DesktopComponent from 'src/hocs/desktop-component';
import MobileComponent from 'src/hocs/mobile-component';
import useIsClient from 'src/hooks/useIsClient';
import useTranslation from 'src/hooks/useTranslation';
import { dateFormat } from 'src/util/date-format';
import { getFluidImage } from 'src/util/get-fluid-image';
import { getShareLinks } from 'src/util/get-share-links';
import { absoluteSlug } from 'src/util/product-slug';
import { StaticContextType } from 'typings/custom';
import { ICustomGiftlist } from 'typings/generated/contentful';

interface Props {
  pageContext: {
    reactContext: StaticContextType;
    giftList: ICustomGiftlist;
  };
}

const LIST_TYPE_THRESHOLD = 50;

const GiftListPage = ({ pageContext }: Props) => {
  const {
    reactContext,
    giftList,
    giftList: {
      fields: { title, description, heroImage },
    },
  } = pageContext;
  const t = useTranslation();
  const isClient = useIsClient();
  const products = isClient ? shuffle(giftList.fields.products) : [];
  const href = absoluteSlug(giftList.fields, isClient);
  const shareLinks = getShareLinks(giftList, href);

  const renderDescription = useCallback(
    () => (
      <ReactMarkdown
        className="text-justify markdown"
        linkTarget="_blank"
        source={description}
      />
    ),
    []
  );

  const renderHeader = useCallback(
    () => (
      <Container>
        <div>
          <MobileComponent>
            <h1 className="py-4 text-3xl text-center title">{title}</h1>
            <WideContainer>
              {heroImage && (
                <GatsbyImage
                  backgroundColor={COLOR_GRAY}
                  className="w-screen h-72"
                  fadeIn={false}
                  fluid={getFluidImage(heroImage)}
                />
              )}
            </WideContainer>
            <p className="py-4 pb-16">{renderDescription()}</p>
            <WideContainer>
              <Divider />
            </WideContainer>
          </MobileComponent>
          <DesktopComponent>
            <div className="flex flex-col space-y-8">
              <div className="flex space-x-16">
                <GatsbyImage
                  backgroundColor={COLOR_GRAY}
                  className="w-56 h-56 rounded"
                  fadeIn={false}
                  fluid={getFluidImage(heroImage, {}, 1)}
                />
                <div className="flex flex-col flex-1 space-y-4">
                  <h1 className="text-5xl font-bold title">{title}</h1>
                  <div className="flex py-2 space-x-4">
                    {shareLinks.map(
                      ({ icon: Icon, link, ariaLabel }, index) => (
                        <a
                          aria-label={ariaLabel}
                          className="w-8 h-8 share-popup"
                          href={link}
                          key={index.toString()}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Icon className="h-full" />
                        </a>
                      )
                    )}
                  </div>
                  <div className="text-sm text-gray-600">
                    {t('published')}: {dateFormat(giftList.sys.createdAt)}
                    <span className="mx-2">|</span>
                    {t('updated')}: {dateFormat(giftList.sys.updatedAt)}
                  </div>
                </div>
              </div>
              <div>
                <p className="py-4">{renderDescription()}</p>
              </div>
            </div>
            <div className="h-24" />
          </DesktopComponent>
        </div>
      </Container>
    ),
    []
  );

  const renderFeedList = useCallback(
    () => (
      <FeedProductListDesktop
        hasMore={false}
        loadMore={() => null}
        products={products}
      />
    ),
    [products]
  );

  const renderGuideList = useCallback(
    () => (
      <>
        <MobileComponent>
          {products.map((product, index, list) => (
            <Fragment key={product.sys.id}>
              <ProductCardGuideMobile index={index} product={product} />
              {index < list.length - 1 && (
                <WideContainer>
                  <Divider />
                </WideContainer>
              )}
            </Fragment>
          ))}
        </MobileComponent>
        <DesktopComponent>
          <div className="flex flex-col items-center space-y-20">
            {products.map((product, index) => (
              <ProductCardGuide
                index={index}
                key={product.sys.id}
                product={product}
              />
            ))}
          </div>
        </DesktopComponent>
      </>
    ),
    [products]
  );

  return (
    <Layout context={reactContext}>
      <SEO
        description={description}
        image={heroImage?.fields.file.url}
        title={title}
      />
      <div className="flex flex-col items-center">
        {renderHeader()}
        <Container loose>
          {products.length > LIST_TYPE_THRESHOLD && renderFeedList()}
          {products.length <= LIST_TYPE_THRESHOLD && renderGuideList()}
        </Container>
      </div>
    </Layout>
  );
};

export default GiftListPage;
